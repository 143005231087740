<template>
  <div class="auth-layout">
    <div class="auth-layout__top">
      <router-link to="/auth">
        <div class="main-navbar__logo" />
      </router-link>

      <transition name="fade" mode="out-in">
        <p :key="isEn" class="language-switcher" @click.prevent="changeLocale">
          <EnRoundFlag v-if="!isEn" />
          <RuRoundFlag v-else />
          {{ ' ' }}
          <span>{{ $t('buttonLabels.language') }}</span>
        </p>
      </transition>
    </div>

    <transition name="component-fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import EnRoundFlag from '@/components/Icons/EnRoundFlag.vue';
import RuRoundFlag from '@/components/Icons/RuRoundFlag.vue';

export default {
  name: 'AuthLayout',
  components: { EnRoundFlag, RuRoundFlag },
  methods: {
    changeLocale() {
      const { availableLocales } = this.$root.$i18n;
      const languageToSelect = availableLocales.find((l) => l !== this.$root.$i18n.locale);
      this.$root.$i18n.locale = languageToSelect;
      localStorage.setItem('locale', languageToSelect);
    },
  },
  computed: {
    isEn() {
      return this.$i18n?.locale === 'en';
    },
  },
};
</script>
