<template>
  <div id="app">
    <component
      :is="layout"
      :subscriptionOnboarding="subscriptionOnboarding"
      @closeWelcome="closeWelcome"
      @skip="skipSubscriptionOnboarding"
    >
      <router-view />
    </component>
    <SplashScreen />
    <LmsToaster />
    <CookiesNotification />
  </div>
</template>
<script>
import SplashScreen from '@/components/Loader/SplashScreen.vue';
import LmsToaster from '@/components/LmsToaster.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import CookiesNotification from '@/components/CookiesNotification.vue';

export default {
  components: { LmsToaster, SplashScreen, CookiesNotification },
  data: () => ({
    subscriptionOnboarding: {
      welcome: false,
      subscribeButton: false,
    },
  }),
  computed: {
    layout() {
      return this.$route.meta?.layout === 'auth' ? AuthLayout : MainLayout;
    },
  },
  beforeCreate() {
    this.$store.dispatch('config/fetchConfiguration');
  },
  created() {
    window.addEventListener('resize', this.heightMobileFix);
  },
  mounted() {
    this.heightMobileFix();
  },
  methods: {
    heightMobileFix() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    skipSubscriptionOnboarding() {
      this.subscriptionOnboarding.welcome = false;
      this.subscriptionOnboarding.subscribeButton = false;
    },
    closeWelcome() {
      this.subscriptionOnboarding.welcome = false;
    },
  },
  watch: {
    $route(to, from) {
      if (from?.name === 'Registration' && to.name === 'Programs') {
        this.subscriptionOnboarding.welcome = true;
        this.subscriptionOnboarding.subscribeButton = true;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.heightMobileFix);
  },
};
</script>
