<template>
  <!-- eslint-disable max-len -->
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.49981 15.075V11.625L2.9248 13.525C3.6498 14.2 4.5248 14.725 5.49981 15.075"
      fill="#2A5F9E"
    />
    <path
      d="M10.5 15.0746C11.475 14.7246 12.35 14.1996 13.075 13.5246L10.5 11.5996V15.0746Z"
      fill="#2A5F9E"
    />
    <path
      d="M0.924805 10.5C0.999805 10.75 1.0998 10.975 1.2248 11.225L2.1998 10.5H0.924805Z"
      fill="#2A5F9E"
    />
    <path
      d="M13.7998 10.5L14.7748 11.225C14.8748 11 14.9748 10.75 15.0748 10.5H13.7998Z"
      fill="#2A5F9E"
    />
    <path
      d="M5.87539 9.5H0.650391C0.725391 9.85 0.825391 10.175 0.925391 10.5H2.20039L1.22539 11.225C1.42539 11.65 1.65039 12.025 1.92539 12.4L4.50039 10.5H5.50039V11L2.57539 13.15L2.92539 13.5L5.50039 11.625V15.075C5.82539 15.2 6.15039 15.275 6.50039 15.35V9.5H5.87539Z"
      fill="white"
    />
    <path
      d="M15.35 9.5H9.5V15.35C9.85 15.275 10.175 15.175 10.5 15.075V11.625L13.075 13.5C13.425 13.175 13.725 12.825 14.025 12.45L11.35 10.5H13.05L14.575 11.625C14.65 11.5 14.725 11.35 14.775 11.225L13.8 10.5H15.075C15.175 10.175 15.275 9.85 15.35 9.5"
      fill="white"
    />
    <path
      d="M1.9248 12.4004C2.1248 12.6754 2.3248 12.9254 2.5498 13.1754L5.49981 11.0254V10.5254H4.49981L1.9248 12.4004Z"
      fill="#ED4C5C"
    />
    <path
      d="M11.375 10.5L14.05 12.45C14.15 12.325 14.225 12.2 14.325 12.075C14.35 12.05 14.35 12.025 14.375 12.025C14.45 11.9 14.55 11.75 14.625 11.625L13.05 10.5H11.375Z"
      fill="#ED4C5C"
    />
    <path
      d="M10.5 0.925781V4.37578L13.075 2.47578C12.35 1.80078 11.475 1.27578 10.5 0.925781Z"
      fill="#2A5F9E"
    />
    <path
      d="M5.49981 0.925781C4.5248 1.27578 3.6498 1.80078 2.9248 2.47578L5.49981 4.40078V0.925781Z"
      fill="#2A5F9E"
    />
    <path
      d="M15.0748 5.50039C14.9998 5.25039 14.8998 5.02539 14.7748 4.77539L13.7998 5.50039H15.0748"
      fill="#2A5F9E"
    />
    <path
      d="M2.1998 5.50039L1.2248 4.77539C1.1248 5.02539 1.0248 5.25039 0.924805 5.50039H2.1998Z"
      fill="#2A5F9E"
    />
    <path
      d="M10.125 6.50039H15.325C15.25 6.15039 15.15 5.82539 15.05 5.50039H13.775L14.75 4.77539C14.55 4.35039 14.325 3.97539 14.05 3.60039L11.5 5.50039H10.5V5.00039L13.425 2.85039L13.075 2.50039L10.5 4.37539V0.925391C10.175 0.800391 9.85 0.725391 9.5 0.650391V6.50039H10.125Z"
      fill="white"
    />
    <path
      d="M0.650391 6.50039H6.50039V0.650391C6.15039 0.725391 5.82539 0.825391 5.50039 0.925391V4.37539L2.92539 2.50039C2.57539 2.82539 2.27539 3.17539 1.97539 3.55039L4.65039 5.50039H2.95039L1.42539 4.37539C1.35039 4.50039 1.27539 4.65039 1.22539 4.77539L2.20039 5.50039H0.925391C0.825391 5.82539 0.725391 6.15039 0.650391 6.50039Z"
      fill="white"
    />
    <path
      d="M14.075 3.59922C13.875 3.32422 13.675 3.07422 13.45 2.82422L10.5 4.97422V5.47422H11.5L14.075 3.59922"
      fill="#ED4C5C"
    />
    <path
      d="M4.62539 5.50078L1.97539 3.55078C1.87539 3.67578 1.80039 3.80078 1.70039 3.92578C1.67539 3.95078 1.67539 3.97578 1.65039 3.97578C1.57539 4.10078 1.47539 4.25078 1.40039 4.37578L2.92539 5.50078H4.62539Z"
      fill="#ED4C5C"
    />
    <path
      d="M15.35 6.5H9.5V0.65C9.025 0.55 8.525 0.5 8 0.5C7.475 0.5 6.975 0.55 6.5 0.65V6.5H0.65C0.55 6.975 0.5 7.475 0.5 8C0.5 8.525 0.55 9.025 0.65 9.5H6.5V15.35C6.975 15.45 7.475 15.5 8 15.5C8.525 15.5 9.025 15.45 9.5 15.35V9.5H15.35C15.45 9.025 15.5 8.525 15.5 8C15.5 7.475 15.45 6.975 15.35 6.5Z"
      fill="#ED4C5C"
    />
  </svg>
</template>

<script>
export default {
  name: 'EnRoundFlag',
};
</script>
