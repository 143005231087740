export default {
  namespaced: true,

  state: {
    isModalTypeCreated: false,
    currentModalType: null,
  },

  actions: {
    setModalTypeIsCreated({ commit }, bool) {
      commit('SET_MODAL_TYPE_IS_CREATED', bool);
    },
    setCurrentModalType({ commit }, type) {
      commit('SET_CURRENT_MODAL_TYPE', type);
    },
    resetCurrentModalType({ commit }) {
      commit('SET_CURRENT_MODAL_TYPE', null);
    },
  },

  mutations: {
    SET_MODAL_TYPE_IS_CREATED(state, bool) {
      state.isModalTypeCreated = bool;
    },
    SET_CURRENT_MODAL_TYPE(state, type) {
      state.currentModalType = type;
    },
  },

  getters: {
    getIsModalTypeCreated: ({ isModalTypeCreated }) => isModalTypeCreated,
    getCurrentModalType: ({ currentModalType }) => currentModalType,
  },
};
