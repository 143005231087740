const url = 'https://hesk.lera.center-game.com/submit_ticket.php?submit=1';

class HeskService {
  submitForm = (form) => {
    const payload = {
      priority: 'low', // optional, default is low/ mandatory
      hx: '3', // mandatory, antispam
      hy: '', // mandatory, antispam
      ...form,
    };

    const params = new URLSearchParams();
    Object.keys(payload).forEach((key) => {
      params.append(key, payload[key]);
    });

    return fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: params,
    }).then((response) => {
      if (!response.ok) throw new Error(response.status);
      return response;
    });
  };
}

export default new HeskService();
