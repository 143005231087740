import StudentService from '@/services/student.service';
import { ActivityStatuses } from '../../../utils/statusConstants';

export default {
  namespaced: true,

  state: {
    questions: [],
  },

  actions: {
    fetchQuestions({ commit }, payload) {
      return StudentService.getStudentActivitiesWithParent(payload).then(({ data }) => {
        commit('SET_QUESTIONS', data);
        return data;
      });
    },
    sendAnswers({ commit, rootGetters }, { question, selectedAnswers }) {
      const allQuestions = rootGetters['quizStudent/getQuestions'];
      // eslint-disable-next-line max-len
      const isLastQuestion = allQuestions.filter((q) => q.statistic.status !== ActivityStatuses.notStarted).length === 1;

      return StudentService.submitQuestionAnswers({
        id: question._id,
        answers: selectedAnswers,
      }).then(({ data }) => {
        // eslint-disable-next-line no-shadow
        const cloneQuestion = JSON.parse(JSON.stringify(question));
        cloneQuestion.statistic.meta = data;
        cloneQuestion.statistic.status = ActivityStatuses.done;
        commit('UPDATE_QUESTION', cloneQuestion);
        console.log(isLastQuestion);
        return isLastQuestion;
      });
    },
    resetQuestions({ commit }) {
      commit('RESET_QUESTIONS');
    },
  },

  mutations: {
    SET_QUESTIONS(state, questions) {
      state.questions = questions;
    },
    UPDATE_QUESTION(state, question) {
      state.questions = state.questions.map((q) => (q._id === question._id ? question : q));
    },
    RESET_QUESTIONS(state) {
      state.questions = [];
    },
  },

  getters: {
    getQuestions: ({ questions }) => questions,
    isQuizCompleted: ({ questions }) => {
      const completedQuestions = questions.filter((q) => q.statistic.status
      === ActivityStatuses.done);
      return completedQuestions.length === questions.length;
    },
  },
};
