<template>
  <div class="footer">
    <div class="container">
      <div class="footer__top">
        <div class="flex-top">
          <a href="https://center-game.com/" target="_blank" rel="noopener noreferrer" class="logo-link">
            <img src="@/assets/images/png/footer-logo.png" alt="" />
          </a>
          <!-- <div class="support-title d-sm-none d-md-block">
            {{ $t('footer.supportContact') }}&nbsp;<wbr />
            <strong>+7(999) 999-99-99</strong>
          </div> -->
          <p class="language-switcher" @click.prevent="changeLocale">
            <transition name="fade" mode="out-in">
              <EnRoundFlag v-if="!isEn" />
              <RuRoundFlag v-else />
            </transition>
            {{ ' ' }}
            <span>{{ $t('buttonLabels.language') }}</span>
          </p>
        </div>

        <!-- <div class="support-title d-none d-sm-block d-md-none">
          {{ $t('footer.supportContact') }}&nbsp;<strong>+7(999) 888-99-99</strong>
        </div> -->
      </div>
      <div class="footer__bottom">
        <div class="left">
          © 2022
          <a href="https://center-game.com/" target="_blank" rel="noopener noreferrer"
            >center-game.com</a
          >
        </div>
        <div class="right">
          <a :href="getLocaleLink.privacyPolicy" target="_blank" rel="noopener noreferrer">{{
            $t('footer.privacyPolicy')
          }}</a>
          <a :href="getLocaleLink.userAgreement" target="_blank" rel="noopener noreferrer"
            >{{ $t('footer.userAgreement') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EnRoundFlag from '@/components/Icons/EnRoundFlag.vue';
import RuRoundFlag from '@/components/Icons/RuRoundFlag.vue';

export default {
  name: 'Footer',
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  components: { EnRoundFlag, RuRoundFlag },
  methods: {
    changeLocale() {
      const { availableLocales } = this.$root.$i18n;
      const languageToSelect = availableLocales.find((l) => l !== this.$root.$i18n.locale);
      this.$root.$i18n.locale = languageToSelect;
      localStorage.setItem('locale', languageToSelect);
    },
  },
  computed: {
    isEn() {
      return this.$i18n?.locale === 'en';
    },
    getLocaleLink() {
      if (this.isEn) {
        return {
          publicOfferAgreement: '#',
          privacyPolicy: `${this.publicPath}user-agreement-ru.pdf`,
          userAgreement: `${this.publicPath}user-agreement-ru.pdf`,
        };
      }
      return {
        publicOfferAgreement: '#',
        privacyPolicy: `${this.publicPath}user-agreement-ru.pdf`,
        userAgreement: `${this.publicPath}user-agreement-ru.pdf`,
      };
    },
  },
};
</script>
