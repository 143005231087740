<template>
  <!-- eslint-disable max-len -->
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.9748 0.5C4.7248 0.5 1.9498 2.6 0.924805 5.5H15.0748C14.0248 2.6 11.2498 0.5 7.9748 0.5Z"
      fill="#F9F9F9"
    />
    <path
      d="M7.9748 15.5C11.2498 15.5 14.0248 13.4 15.0498 10.5H0.924805C1.9498 13.425 4.7248 15.5 7.9748 15.5Z"
      fill="#ED4C5C"
    />
    <path
      d="M0.925 5.5C0.65 6.275 0.5 7.125 0.5 8C0.5 8.875 0.65 9.725 0.925 10.5H15.075C15.35 9.725 15.5 8.875 15.5 8C15.5 7.125 15.35 6.275 15.075 5.5H0.925Z"
      fill="#428BC1"
    />
  </svg>
</template>

<script>
export default {
  name: 'RuRoundFlag',
};
</script>
