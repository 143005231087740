<template>
  <div class="main-layout" id="mainLayout">
    <router-view />
    <Footer v-if="isFooterShown" />
    <transition name="component-fade" mode="out-in">
      <div
        v-if="subscriptionOnboarding.welcome || subscriptionOnboarding.subscribeButton"
        ref="wrapper"
        class="welcome-onboarding-wrapper"
        :class="{
          'welcome-onboarding-wrapper__step2':
            !subscriptionOnboarding.welcome && subscriptionOnboarding.subscribeButton,
        }"
      >
        <OnboardingCard
          v-if="subscriptionOnboarding.welcome"
          ref="step1"
          variant="big"
          class="welcome-onboarding"
          :left-button-label="$t('buttonLabels.continueFree')"
          :right-button-label="$t('buttonLabels.chooseTariff')"
          tutorial-step="chose-subscription"
          @tutorial="onTutorial"
        >
          <template #title>{{ $t('header.welcomeToTheInternet') }}</template>
          <template #text>{{ $t('supportText.thanksForRegistrationFreeActivated') }}</template>
        </OnboardingCard>
        <OnboardingCard
          v-if="!subscriptionOnboarding.welcome && subscriptionOnboarding.subscribeButton"
          v-click-outside="onClickOutside"
          :leftButtonLabel="$t('buttonLabels.ok')"
          @tutorial="onTutorial"
          tutorialStep="skip"
          :style="componentStyle"
        >
          <template #title>{{ $t('header.payTariff') }}</template>
          <template #label>{{ $t('supportText.hereYouCanChooseTariff') }}</template>
        </OnboardingCard>
      </div>
    </transition>
    <Hesk />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Footer from '@/components/Footer.vue';
import OnboardingCard from '@/components/Cards/OnboardingCard.vue';
import Hesk from '@/components/Hesk.vue';

export default {
  name: 'MainLayout',
  components: { OnboardingCard, Hesk, Footer },
  props: {
    subscriptionOnboarding: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    componentStyle: '',
    step2Inited: false,
  }),
  computed: {
    ...mapGetters({
      isTeacher: 'auth/isModeTeacher',
    }),
    isFooterShown() {
      const footerPages = [
        'Dashboard',
        'CourseUser',
        'ManageTariffs',
        'TariffsDetailed',
        'PostPaidPage',
      ];
      return this.isTeacher || footerPages.includes(this.$route.name);
    },
  },
  methods: {
    onTutorial(step) {
      if (step === 'chose-subscription') {
        return this.$router.push('/manage-tariffs').then(() => this.$emit('skip'));
      }
      if (step === 'skip') {
        if (this.subscriptionOnboarding.welcome) {
          return this.$emit('closeWelcome');
        }
        return this.$emit('skip');
      }
      this.$emit('skip');
    },
    onClickOutside() {
      if (this.step2Inited) {
        this.$emit('skip');
      }
      this.step2Inited = true;
    },
  },
  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },
  watch: {
    'subscriptionOnboarding.welcome': {
      handler(bool) {
        if (!bool && this.subscriptionOnboarding.subscribeButton) {
          const tariffsLink = document.getElementById('tariffs-link');
          if (tariffsLink) {
            const { left } = tariffsLink.getBoundingClientRect();
            this.componentStyle = `left:${+left - 290}px;`;
          }
        }
      },
    },
  },
};
</script>
